
<template>

  <!-- The below layout is coppied from docs root -->

  <!-- This max-height style is a must becouse otherwise
  there is going to be a scroll bar that scrolls nowhere.
  Also, there is another height set at n-lauout has-sider
  that one is necessary as well :D this is somehow too complicated -->
  <n-layout style="max-height: 100vh">
    <n-layout-header bordered>
      <SiteHeader />
    </n-layout-header>
    <n-layout has-sider style="height: 93vh;">
      <Menu />
      <n-layout-content
        :native-scrollbar="false"
      >
        <router-view />
        <!-- <n-layout-footer :style="this.isMobile ? 'padding: 8px;' : 'padding: 64px;'" bordered>
          <footer_ />
        </n-layout-footer> -->
      </n-layout-content>
    </n-layout>
  </n-layout>

</template>

<script>
import SiteHeader from '@/components/SiteHeader.vue'
import Menu from '@/pages/admin/Menu.vue'
// import footer_ from '@/components/footer_.vue'
import { defineComponent } from 'vue'


export default defineComponent({
  components: {
    SiteHeader,
    Menu,
    // footer_,
  },
  setup () {
    return {
    }
  }
})
</script>

<style scoped>
</style>
