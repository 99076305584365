<template>
  <n-layout-sider
    bordered
    collapse-mode="width"
    :collapsed-width="64"
    :width="240"
    show-trigger
  >
    <n-h4 prefix="bar" type="primary" style="margin: 15px">ADMINISTRATORIAUS PASKYRA</n-h4>
    <n-divider />
    <n-menu
      :options="menuOptions"
      :indent="16"
      :root-indent="16"
      :collapsed-width="64"
      :collapsed-icon-size="12"
      :default-expand-all="false"
      style="width: 240px;"
      :default-value="'E-vedlio statistika'"
    />
  </n-layout-sider>
</template>

<script>

import { defineComponent, h, resolveComponent } from 'vue'
import { NIcon } from 'naive-ui'
import {
  BookOutline as BookIcon,
  PersonOutline as PersonIcon,
  HomeOutline as HomeIcon,
  StatsChartOutline,
  AlbumsOutline,
  ClipboardOutline,
} from '@vicons/ionicons5'

function renderIcon (icon) {
  return () => h(NIcon, null, { default: () => h(icon) })
}

const menuOptions = [
  {
    label: () =>
      h(
        resolveComponent('router-link'),
        {
          to: {
            path: 'admin_/statsMain',
          }
        },
        { default: () => 'E-vedlio statistika' }
      ),
    key: 'E-vedlio statistika',
    icon: renderIcon(HomeIcon)
  },
  {
    label: () =>
      h(
        resolveComponent('router-link'),
        {
          to: {
            path: 'admin_/statsLessons',
          }
        },
        { default: () => 'Modulių statistika' }
      ),
    key: 'Testų statistika',
    icon: renderIcon(ClipboardOutline)
  },
  {
    label: () =>
      h(
        resolveComponent('router-link'),
        {
          to: {
            path: 'admin_/users',
          }
        },
        { default: () => 'Vartotojų nustatymai' }
      ),
    key: 'Vartotojų nustatymai',
    icon: renderIcon(PersonIcon)
  },
  {
    label: () =>
      h(
        resolveComponent('router-link'),
        {
          to: {
            path: 'admin_/editorMenu',
          }
        },
        { default: () => 'Mokymosi turinys' }
      ),
    key: 'Mokymosi turinys',
    icon: renderIcon(BookIcon)
  },
  {
    label: () =>
      h(
        resolveComponent('router-link'),
        {
          to: {
            path: 'admin_/editorContent',
          }
        },
        { default: () => 'Mokymosi medžiaga' }
      ),
    key: 'Mokymosi medžiaga',
    icon: renderIcon(AlbumsOutline)
  },
  {
    label: () =>
      h(
        resolveComponent('router-link'),
        {
          to: {
            path: 'admin_/statsServer',
          }
        },
        { default: () => 'Serverio statistika' }
      ),
    key: 'Serverio statistika',
    icon: renderIcon(StatsChartOutline)
  },
]

export default defineComponent({
  components: {
  },
  setup () {
    return {
      menuOptions,
    }
  }
})
</script>

<style scoped>
</style>
